import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
    typography: {
        color: 'white'
    },
    palette: {
        type: 'dark',
        primary: {
            main: '#e8c467',
            dark: '#9d7521',
            light: '#f0d97e'
        },
        secondary: {
            main: '#19857b',
        },
        error: {
            main: red.A400,
        },
        background: {
            default: '#121212',
        },
    },
});

export default theme;